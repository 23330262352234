import './Inputs.scss';

import autobind from 'react-autobind';
import classNames from 'classnames';
import React from 'react';
import { FormGroup, FormLabel } from '@mui/material';
import InputMui from '@mui/material/Input';

/**
 * Implements a floating label form group.
 *
 * This component automatically creates a form group with a <label> and <input>
 * elements. By default, all props passed to this component will get added to
 * the `<input>` element. This component also defines a few new properties:
 *
 *   - label: The string that will be included in the `<label>` element. This
 *     same string will be used as the placeholder in the `<input>` element.
 *   - formGroupId: A value to be set on the `id` attribute of the form group.
 *   - formGroupClasses: A set of class names to be set on the `class` attribute
 *     of the form group.
 *   - inputClasses: A set of class names to be set on the `class` attribute
 *     of the `<input>` element.
 */
class FloatingLabelFormGroup extends React.Component {
  constructor(props) {
    super(props);
    autobind(this);

    this.state = { isPopulated: !!this.props.value };
  }

  render() {
    var {
        label,
        placeholder,
        formGroupId,
        formGroupClasses,
        inputClasses,
        helpText,
        ...props
      } = this.props,
      inputId = 'c-' + this.props.name;

    formGroupClasses = classNames(formGroupClasses, 'form-group', 'floating-label', {
      populated: this.state.isPopulated,
    });

    // In React + IE11, there's a bug where paste events don't correctly trigger
    // an `onChange` event. We need to make sure we also trigger an `onInput`
    // event here in order to make things work correctly.
    if (props.onChange && !props.onInput) {
      props.onInput = props.onChange;
    }
    /* we always need placeholder for empty input style*/
    return (
      <Wrapper
        inputId={inputId}
        label={label}
        formGroupClasses={formGroupClasses}
        formGroupId={formGroupId}
      >
        <Input
          {...props}
          id={inputId}
          className={inputClasses}
          placeholder={placeholder || label || ' '}
          onPopulate={this.handlePopulate}
        />
        {helpText && <div className="help-block">{helpText}</div>}
      </Wrapper>
    );
  }

  /**
   * Sets `this.state.isPopulated` based on the given boolean value.
   *
   * @param {boolean} isPopulated - Whether the input field was populated.
   */
  handlePopulate(isPopulated) {
    // Only update the state if needed. This is needed to prevent an infinite
    // recursive loop.
    if (isPopulated !== this.state.isPopulated) {
      this.setState({ isPopulated: isPopulated });
    }
  }
}

const Wrapper = ({ children, formGroupClasses, formGroupId, inputId, label }) => {
  if (label && label.length) {
    return (
      <FormGroup className={formGroupClasses} id={formGroupId}>
        <FormLabel className="control-label" htmlFor={inputId}>
          {label}
        </FormLabel>
        {children}
      </FormGroup>
    );
  }

  return <>{children}</>;
};

/**
 * Creates an `<input>` element.
 */
class Input extends React.Component {
  constructor(props) {
    super(props);
    autobind(this);
  }

  componentWillReceiveProps(nextProps) {
    if ('value' in nextProps) {
      this.props.onPopulate(!!nextProps.value);
    }
  }

  render() {
    // Filter out props we don't want to pass directly to the `<input>` element.
    var { onChange, onPopulate, ...props } = this.props;
    return (
      <InputMui
        variant="outlined"
        size="small"
        {...props}
        className={classNames('form-control searchInputMui', this.props.className)}
        onChange={this.handleChange}
      />
    );
  }

  handleChange(e) {
    this.props.onPopulate(!!e.target.value);
    this.props.onChange(e);
  }
}

Input.defaultProps = {
  // If a `type` attribute isn't set for an `<input>` element, then we should
  // assume it's a generic text input.
  type: 'text',
};

export { FloatingLabelFormGroup, Input };
