import React from 'react';

const ErrorIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
    >
      <path
        d="M22.3157 21.1367L22.3222 21.1302L22.3285 21.1234C22.9376 20.4674 22.9557 19.4528 22.3157 18.8128L19.9578 16.4549C19.7151 16.2122 19.7151 15.7878 19.9578 15.5451L22.3157 13.1872L22.3222 13.1807L22.3285 13.1739C22.9376 12.5179 22.9557 11.5034 22.3157 10.8633L21.103 9.65066L21.0965 9.64411L21.0897 9.63781C20.4337 9.02869 19.4191 9.01059 18.7791 9.65066L16.4285 12.0012C16.1418 12.2621 15.7455 12.2426 15.5114 12.0086L13.1536 9.65066L13.147 9.64411L13.1402 9.63781C12.4842 9.02869 11.4697 9.01059 10.8296 9.65066L9.61697 10.8633L9.61043 10.8698L9.60413 10.8766C8.99501 11.5326 8.97691 12.5472 9.61697 13.1872L11.9675 15.5378C12.2284 15.8245 12.209 16.2208 11.9749 16.4549L9.61697 18.8128L9.61043 18.8193L9.60413 18.8261C8.99501 19.4821 8.97691 20.4966 9.61697 21.1367L10.8296 22.3493L10.8362 22.3559L10.8429 22.3622C11.4989 22.9713 12.5135 22.9894 13.1536 22.3493L15.5041 19.9988C15.7908 19.7379 16.1871 19.7574 16.4212 19.9914L18.7791 22.3493L18.7856 22.3559L18.7924 22.3622C19.4484 22.9713 20.463 22.9894 21.103 22.3493L22.3157 21.1367ZM5.62526 0.5H26.3747C29.1975 0.5 31.5 2.80246 31.5 5.62526V26.3747C31.5 29.1975 29.1975 31.5 26.3747 31.5H5.62526C2.80246 31.5 0.5 29.1975 0.5 26.3747V5.62526C0.5 2.80246 2.80246 0.5 5.62526 0.5Z"
        fill="white"
        stroke="#B92626"
      />
    </svg>
  );
};

export default ErrorIcon;
