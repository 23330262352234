'use strict';

import React from 'react';
import ReactIcon from 'react-icons-kit';

/**
 * Simple wrapper around the react `Icon` component that forces it to be
 * vertical aligned.
 */
function Icon(props) {
  var { style, ...props } = props;

  style = style || {};
  style.display = 'inline-flex';
  if (!style.cursor) {
    style.cursor = 'pointer';
  }
  if (!style.verticalAlign) {
    style.verticalAlign = 'middle';
  }

  return <ReactIcon {...props} style={style} />;
}

export default Icon;
