'use strict';

import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';
import { createStore, applyMiddleware } from 'redux';
import { ThemeProvider } from '@mui/material/styles';
import CustomSnackBarProvider from 'common/components/Snackbar/CustomSnackBarProvider';

import thunk from 'redux-thunk';
import history from './history';
import reducers from './reducers';
import routes from './routes';
import { themeMui } from 'common/helpers/themeMui';

const store = createStore(reducers, window.G, applyMiddleware(thunk));

render(
  <Provider store={store}>
    <ThemeProvider theme={themeMui}>
      <CustomSnackBarProvider>
        <Router history={history}>{routes}</Router>
      </CustomSnackBarProvider>
    </ThemeProvider>
  </Provider>,
  document.getElementById('app-root')
);
