import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import styledComponents from 'styled-components';
import { ic_close } from 'react-icons-kit/md/ic_close';
import Icon from './Icon';

const Content = styledComponents(DialogContent)`
  ::-webkit-scrollbar {
    -webkit-appearance: none;
  }

  ::-webkit-scrollbar:vertical {
    width: 11px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 8px;
    border: 2px solid #fafafa;
    background-color: rgba(0, 0, 0, 0.5);
  }
  &.withOverflow {
    overflow: visible;
  }
`;

const DialogWrapper = styledComponents(Dialog)`
  &.withOverflow .MuiDialog-paper {
    overflow: visible;
  }
`;

export const Modal = ({
  open,
  onClose,
  title,
  actions,
  children,
  maxWidth = 'sm',
  fullWidth = true,
  isDefaultCloseButton = false,
  contentClassName,
  ...props
}) => {
  return (
    <DialogWrapper
      open={open}
      onClose={onClose}
      maxWidth={maxWidth}
      fullWidth={fullWidth}
      {...props}
    >
      {title && (
        <DialogTitle>
          {title}

          {isDefaultCloseButton && (
            <Icon
              icon={ic_close}
              style={{ position: 'absolute', right: 8, top: 8 }}
              onClick={onClose}
            />
          )}
        </DialogTitle>
      )}
      <Content className={contentClassName}>{children}</Content>
      <DialogActions>{actions}</DialogActions>
    </DialogWrapper>
  );
};
