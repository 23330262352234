'use strict';

import React from 'react';
import { Field } from 'redux-form';

import { FloatingLabelFormGroup } from 'common/components/Inputs';

const wrapFloatingLabelFormGroup = (props) => {
  var { input, meta, ...props } = props;
  return (
    <FloatingLabelFormGroup
      name={input.name}
      value={input.value}
      onChange={input.onChange}
      {...props}
    />
  );
};

const ReduxFormFloatingLabelFormGroup = (props) => {
  return <Field {...props} component={wrapFloatingLabelFormGroup} />;
};

export default ReduxFormFloatingLabelFormGroup;
