'use strict';

import moment from 'moment';
import _lowerCase from 'lodash/lowerCase';
import * as constants from './constants';

/**
 * Returns the given numeric value as a comma-separated string.
 */
export function toCommaSeparated(val) {
  return val.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
}

/**
 * Converts the given string to sentence case.
 */
export function toSentenceCase(val) {
  const lowerCased = _lowerCase(val);
  return lowerCased[0].toUpperCase() + lowerCased.substring(1);
}

/**
 * Returns the given numeric value as a dollar-formatted string.
 *
 * The resulting string with have two decimals of precision, and comma
 * separators at each thousand increment. However, it will *not* have a dollar
 * sign prefix by default.
 */
export function toDollars(val) {
  return toCommaSeparated(parseFloat(val).toFixed(2));
}

/**
 * Converts the given hex + opacity values to RGBA.
 */
export function hexToRGBA(hex, opacity) {
  hex = hex.replace('#', '');
  opacity = opacity !== undefined ? opacity : 1;

  var r = parseInt(hex.substring(0, 2), 16),
    g = parseInt(hex.substring(2, 4), 16),
    b = parseInt(hex.substring(4, 6), 16);

  return 'rgba(' + r + ',' + g + ',' + b + ',' + opacity + ')';
}

/**
 * Converts the given hex to RGB.
 */
export function hexToRGB(hex) {
  hex = hex.replace('#', '');

  var r = parseInt(hex.substring(0, 2), 16),
    g = parseInt(hex.substring(2, 4), 16),
    b = parseInt(hex.substring(4, 6), 16);

  return 'rgb(' + r + ',' + g + ',' + b + ')';
}

export function generateRandomHexColor() {
  return '#' + Math.floor(Math.random() * 16777215).toString(16);
}

/**
 * Returns the name of the current page.
 *
 * This looks for the `data-page-name` data attribute on the `body` element.
 */
export function getPageName() {
  var body = document.getElementsByTagName('body')[0];
  return body.getAttribute('data-page-name');
}

/**
 * Returns the sum of the elements in the given array.
 */
export function sum(array) {
  return array.reduce((a, b) => a + b, 0);
}

/**
 * Exports the lodash `sortBy` function because it's really useful.
 */
export { default as sortBy } from 'lodash/sortBy';

/**
 * Returns value in the given object accessed by the given string path.
 *
 * The path can be of the format 'part1.part2' or 'part3[0].name'.
 *
 * Source: http://stackoverflow.com/questions/6491463/accessing-nested-
 *     javascript-objects-with-string-key
 */
export function getByPath(object, path) {
  if (!object) {
    return;
  }

  // Convert indexes to properties.
  path = path.replace(/\[(\w+)\]/g, '.$1');
  // strip a leading dot
  path = path.replace(/^\./, '');

  var pathComponents = path.split('.');
  for (var i = 0, n = pathComponents.length; i < n; ++i) {
    var k = pathComponents[i];
    if (k in object) {
      object = object[k];
    } else {
      return null;
    }
  }
  return object;
}

/**
 * Sets the given value on the object at the given dot-notated path.
 *
 * If part of the path doesn't exist, then it'll be created.
 */
export function setByPath(object, path, value) {
  var pathComponents = path.split('.');

  for (var i = 0; i < pathComponents.length - 1; i++) {
    var el = pathComponents[i];
    if (!object[el]) {
      object[el] = {};
    }
    object = object[el];
  }
  object[pathComponents[pathComponents.length - 1]] = value;
}

/**
 * Removes all elements from the given array.
 *
 * Use this method to remove all elements from an array without using `array =
 * []`, which could break references to the original array.
 */
export function clearArray(array) {
  array.splice(0, array.length);
}

/**
 * Helper function that copies the given text to the clipboard.
 *
 * This function inserts an invisible `<input/>` field that gets populated with
 * the given text, and then uses `document.execCommand('copy')` to copy it to
 * the clipboard.
 */
export function copyToClipboard(text) {
  let input = document.createElement('input');
  input.setAttribute('value', text);
  input.setAttribute('readonly', true);

  let styles = {
    position: 'absolute',
    top: '-9999px',
    left: '-9999px',
    width: '1px',
    height: '1px',
    padding: 0,
    border: 'none',
    outline: 'none',
  };
  for (let [key, value] of Object.entries(styles)) {
    input.style[key] = value;
  }

  document.body.appendChild(input);
  input.select();
  let result = document.execCommand('copy');
  document.body.removeChild(input);

  return result;
}

/**
 * Triggers the browser to start downloading a file
 *
 * This is implemented by inserting an iframe on the page that points to the
 * given file URL. This allows us to start the download without changing the
 * URL of the page, which causes different effects in different browsers,
 * and which does not fail gracefully (e.g., if there is a server error at
 * the target URL).
 */
export async function triggerDownload(url, errorCallback, successCallback) {
  try {
    const response = await fetch(url, { method: 'HEAD' }); // Use HEAD to minimize data transfer

    if (!response.ok) {
      errorCallback && errorCallback();
    }

    let iframe = document.createElement('iframe');
    iframe.src = url;

    let styles = {
      position: 'absolute',
      top: '-9999px',
      left: '-9999px',
      display: 'none',
      width: 0,
      height: 0,
    };
    for (let [key, value] of Object.entries(styles)) {
      iframe.style[key] = value;
    }

    document.body.appendChild(iframe);

    successCallback && successCallback();
  } catch (error) {
    errorCallback && errorCallback();
  }
}
/**
 * Triggers the browser to download the given CSV content with the given filename.
 *
 * Source: https://stackoverflow.com/questions/14964035/how-to-export-javascript-array-info-to-csv-on-client-side
 */
export function triggerDownloadCSV(content, filename, downloadElementBinding) {
  let blob = new Blob([content], {
    type: 'text/csv;charset=utf-8;',
  });

  // For IE 10 and 11.
  if (navigator.msSaveBlob) {
    navigator.msSaveBlob(blob, filename);
  } else {
    let link = document.createElement('a');
    if (link.download !== undefined) {
      // Browsers that support HTML5 download attribute
      link.href = window.URL.createObjectURL(blob);
      link.download = filename.includes('.csv') ? filename : `${filename}.csv`;
      link.style.visibility = 'hidden';
      link.style.position = 'absolute';
      link.style.top = '-9999px';
      link.style.left = '-9999px';

      const bindingElement = downloadElementBinding || document.body;
      bindingElement.appendChild(link);
      link.click();
      bindingElement.removeChild(link);
    }
  }
}

export function triggerDownloadUrl(url) {
  let link = document.createElement('a');
  link.style.visibility = 'hidden';
  link.style.position = 'absolute';
  link.style.top = '-9999px';
  link.style.left = '-9999px';

  link.href = url;
  link.target = '_blank';
  link.dispatchEvent(new MouseEvent('click'));
  link.remove();
}

/**
 * Converts a given array of arrays to a CSV string.
 */
export function rowsToCSV(rows) {
  function escapeCell(val) {
    if (val instanceof Date) {
      val = val.toLocaleString();
    } else if (typeof val === 'number' && val % 1 !== 0) {
      // If the value is less than 1, then display 4 decimals, otherwise only
      // display 2. Displaying a few more digits of precision is helpful for
      // smaller values (e.g., percentages).
      val = val.toFixed(val < 1 ? 4 : 2);
    } else if (typeof val === 'string') {
      val = val.replace(/"/g, '""');
      if (val.search(/("|,|\n)/g) >= 0) {
        val = '"' + val + '"';
      }
    }
    return val;
  }

  return rows.map((row) => row.map(escapeCell).join(',')).join('\n');
}

/**
 * Returns `true` if the given string looks like a valid email.
 *
 * Source: http://www.regular-expressions.info/email.html
 */
export function isValidEmail(email) {
  if (!email) {
    return false;
  }

  // Thanks http://www.regular-expressions.info/email.html
  let re = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
  return re.test(email.trim());
}

/**
 * Returns the name of the day of the week for the given weekday index.
 */
export function getDayName(ix) {
  const weekdays = [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
  ];
  return weekdays[ix];
}

/**
 * Returns the ordinal suffix (e.g., 'st', 'nd', 'rd', etc.) for the given
 * integer.
 *
 * Source: https://stackoverflow.com/questions/13627308/add-st-nd-rd-and-th-ordinal-suffix-to-a-number
 */
export function getNumberOrdinal(i) {
  var j = i % 10,
    k = i % 100;
  if (j == 1 && k != 11) {
    return i + 'st';
  }
  if (j == 2 && k != 12) {
    return i + 'nd';
  }
  if (j == 3 && k != 13) {
    return i + 'rd';
  }
  return i + 'th';
}

/**
 * Joins the given array of components in a single <div>.
 */
export function joinComponents(...components) {
  return <div>...components</div>;
}

export var Fmt = {
  /**
   * Returns the given numeric value as an int string.
   */
  int(val) {
    if (val === null) {
      return null;
    }
    return toCommaSeparated(parseInt(val));
  },

  /**
   * Returns the given numeric value as a string to one decimal place.
   */
  float1(val) {
    if (val === null) {
      return null;
    }
    return parseFloat(val).toFixed(1);
  },

  /**
   * Returns the given numeric value as a string to two decimal places.
   */
  float2(val) {
    if (val === null) {
      return null;
    }
    return parseFloat(val).toFixed(2);
  },

  /**
   * Returns the given numeric value as a string to two decimal places.
   */
  float3(val) {
    if (val === null) {
      return null;
    }
    return parseFloat(val).toFixed(3);
  },

  /**
   * Returns the given numeric value as a percentage string with a single digit of
   * precision.
   */
  percFloat1(val) {
    if (val === null) {
      return null;
    }
    return (val * 100).toFixed(1) + '%';
  },

  /**
   * Returns the given numeric value as a percentage string with two digits of
   * precision.
   */
  percFloat2(val) {
    if (val === null) {
      return null;
    }
    return (val * 100).toFixed(2) + '%';
  },

  /**
   * Returns the given numeric value as a percentage string with a single digit of
   * precision.
   */
  percInt(val) {
    if (val === null) {
      return null;
    }
    return parseInt(val * 100) + '%';
  },

  /**
   * Returns the given numeric value as a dollar string with two decimal places.
   */
  dollars(val) {
    if (val === null || val === undefined) {
      return null;
    }
    return '$' + toDollars(val);
  },

  /**
   * Returns the given numeric value as a dollar-formatted integer string.
   */
  dollarsInt(val) {
    if (val === null || val === undefined) {
      return null;
    }
    return '$' + toCommaSeparated(parseInt(val));
  },

  /**
   * Returns a locale-formatted date string from the given ISO date string.
   */
  date(val) {
    if (!val) {
      return null;
    }
    return moment(val).format('L');
  },

  /**
   * Returns a locale-formatted date/time string from the given ISO date string.
   */
  dateTime(val) {
    if (!val) {
      return null;
    }
    return moment(val).format('L LT');
  },

  /**
   * Returns the custom format date.
   */
  dateCustomFormat(format = 'MM/DD/YYYY') {
    return function (val) {
      if (val === null) {
        return null;
      }
      return moment(val).format(format);
    };
  },

  toTitleCase(val) {
    return val.replace(/\w\S*/g, function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  },
  /**
   * Returns and `mdash` character if the given value is null.
   */
  mdashIfNull(val) {
    return val === null ? constants.MDASH : val;
  },

  /**
   * Returns a formatter function that returns an `mdash` character if the
   * entered value is null, otherwise, executes the given formatter.
   */
  mdashIfNullFunc(formatter) {
    return (val) => (val === null ? constants.MDASH : formatter(val));
  },

  /**
   * Returns an array of formatted address lines for the given address object.
   */
  addressLines({
    name,
    company,
    street1,
    street2,
    city,
    state,
    postalCode,
    countryCode,
  }) {
    // The name, company, street1, and street2 components should simply get
    // appended.
    let lines = [name, company, street1, street2];

    lines.push(
      [city, [state, postalCode].join(' ')].filter((s) => s && s.length > 0).join(', ')
    );

    lines.push(countryCode);

    // Strip all lines, and filter out any lines that have blank lines.
    return lines.map((s) => s && s.trim()).filter((s) => s && s.length > 0);
  },
};

// Find the max-min value to create heatmap
export const findAbsMaxValue = (arr) => {
  const boundaries = arr.reduce(
    ({ min, max }, subArray) => {
      return {
        min: Math.min(min, ...subArray),
        max: Math.max(max, ...subArray),
      };
    },
    { min: Infinity, max: -Infinity }
  );

  const maxAbs = Math.max(Math.abs(boundaries.min), Math.abs(boundaries.max));

  return maxAbs;
};

export const findAbsMinValue = (arr) => {
  const boundaries = arr.reduce(
    ({ min }, subArray) => {
      const absSubArray = subArray.map((item) => Math.abs(item));
      return {
        min: Math.min(min, ...absSubArray),
      };
    },
    { min: Infinity }
  );

  return boundaries.min;
};

export const findMaxValue = (arr) => {
  const boundaries = arr.reduce(
    ({ max }, subArray) => {
      return {
        max: Math.max(max, ...subArray),
      };
    },
    { max: -Infinity }
  );

  return boundaries.max;
};

export const findMinValue = (arr) => {
  const boundaries = arr.reduce(
    ({ min }, subArray) => {
      return {
        min: Math.min(min, ...subArray),
      };
    },
    { min: Infinity }
  );

  return boundaries.min;
};
export const getVendors = (vendorAccountNumberMappings) => {
  if (!vendorAccountNumberMappings) {
    return [];
  }
  let options = vendorAccountNumberMappings.map((models) => ({
    label: constants.getDispValue(models.vendor),
    value: models.vendor,
  }));

  return [...new Map(options.map((item) => [item['value'], item])).values()];
};

export const getOperationVendors = (operationVendorAccountNumberMappings) => {
  if (!operationVendorAccountNumberMappings) {
    return [];
  }

  let options = operationVendorAccountNumberMappings.map((models) => ({
    label: models.operationVendor,
    value: models.operationVendor,
  }));

  return [...new Map(options.map((item) => [item['value'], item])).values()];
};

export function blobToBase64(blob) {
  return new Promise((resolve, _) => {
    const reader = new FileReader();
    reader.onloadend = () => resolve(reader.result);
    reader.readAsDataURL(blob);
  });
}

/**
 * Shade (Lighten or Darken) color
 * https://stackoverflow.com/questions/5560248/programmatically-lighten-or-darken-a-hex-color-or-rgb-and-blend-colors
 */
export const pSBC = (p, c0, c1, l) => {
  let r,
    g,
    b,
    P,
    f,
    t,
    h,
    m = Math.round,
    a = typeof c1 == 'string';
  if (
    typeof p != 'number' ||
    p < -1 ||
    p > 1 ||
    typeof c0 != 'string' ||
    (c0[0] != 'r' && c0[0] != '#') ||
    (c1 && !a)
  )
    return null;
  (h = c0.length > 9),
    (h = a ? (c1.length > 9 ? true : c1 == 'c' ? !h : false) : h),
    (f = pSBC.pSBCr(c0)),
    (P = p < 0),
    (t =
      c1 && c1 != 'c'
        ? pSBC.pSBCr(c1)
        : P
        ? { r: 0, g: 0, b: 0, a: -1 }
        : { r: 255, g: 255, b: 255, a: -1 }),
    (p = P ? p * -1 : p),
    (P = 1 - p);
  if (!f || !t) return null;
  if (l)
    (r = m(P * f.r + p * t.r)), (g = m(P * f.g + p * t.g)), (b = m(P * f.b + p * t.b));
  else
    (r = m((P * f.r ** 2 + p * t.r ** 2) ** 0.5)),
      (g = m((P * f.g ** 2 + p * t.g ** 2) ** 0.5)),
      (b = m((P * f.b ** 2 + p * t.b ** 2) ** 0.5));
  (a = f.a),
    (t = t.a),
    (f = a >= 0 || t >= 0),
    (a = f ? (a < 0 ? t : t < 0 ? a : a * P + t * p) : 0);
  if (h)
    return (
      'rgb' +
      (f ? 'a(' : '(') +
      r +
      ',' +
      g +
      ',' +
      b +
      (f ? ',' + m(a * 1000) / 1000 : '') +
      ')'
    );
  else
    return (
      '#' +
      (4294967296 + r * 16777216 + g * 65536 + b * 256 + (f ? m(a * 255) : 0))
        .toString(16)
        .slice(1, f ? undefined : -2)
    );
};

pSBC.pSBCr = (d) => {
  const i = parseInt;
  let n = d.length,
    x = {};
  if (n > 9) {
    const [r, g, b, a] = (d = d.split(','));
    n = d.length;
    if (n < 3 || n > 4) return null;
    (x.r = i(r[3] == 'a' ? r.slice(5) : r.slice(4))),
      (x.g = i(g)),
      (x.b = i(b)),
      (x.a = a ? parseFloat(a) : -1);
  } else {
    if (n == 8 || n == 6 || n < 4) return null;
    if (n < 6)
      d = '#' + d[1] + d[1] + d[2] + d[2] + d[3] + d[3] + (n > 4 ? d[4] + d[4] : '');
    d = i(d.slice(1), 16);
    if (n == 9 || n == 5)
      (x.r = (d >> 24) & 255),
        (x.g = (d >> 16) & 255),
        (x.b = (d >> 8) & 255),
        (x.a = Math.round((d & 255) / 0.255) / 1000);
    else (x.r = d >> 16), (x.g = (d >> 8) & 255), (x.b = d & 255), (x.a = -1);
  }
  return x;
};

//

export const searchTableFn = (searchQuery, dataList, keyList) => {
  const searchQueryLowerCase = (searchQuery || '').toLowerCase().replace(/\s/g, '');
  const searchFnCallback = (element) => {
    return element
      ?.toString()
      ?.toLowerCase()
      ?.replace(/\s/g, '')
      ?.includes(searchQueryLowerCase);
  };

  return dataList?.filter((i) =>
    keyList.find((key) => {
      return searchFnCallback(i[key]);
    })
  );
};

function hexToRgb(hex) {
  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
      }
    : null;
}

function rgbToHex(r, g, b) {
  return '#' + ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1);
}

// Generate the color palette
export function createColorPalette(baseColor, numColors) {
  let rgb = hexToRgb(baseColor);
  let palette = [];

  for (let i = 0; i < numColors; i++) {
    let multiplier = 1 - i / numColors;
    let newColor = {
      r: Math.floor(rgb.r * multiplier),
      g: Math.floor(rgb.g * multiplier),
      b: Math.floor(rgb.b * multiplier),
    };
    palette.push(rgbToHex(newColor.r, newColor.g, newColor.b));
  }

  return palette;
}

export function csvStringToObject(csvData) {
  const csvFieldExtractor = /(?<=,|^)(?:"([^"]*(?:""[^"]*)*)"|([^",]*))(?=,|$)/g;

  const lines = csvData.trim().split('\n');
  const headers = lines[0]
    .match(csvFieldExtractor)
    .map((header) => header.replace(/^"|"$/g, '').replace(/""/g, '"'));

  const preparedData = lines.slice(1).map((line) => {
    const fields = [...line.matchAll(csvFieldExtractor)].map((field) =>
      field[1] ? field[1].replace(/""/g, '"') : field[2]
    );

    return headers.reduce((curr, header, index) => {
      curr[header] = fields[index] !== undefined ? fields[index] : '';
      return curr;
    }, {});
  });

  return {
    data: preparedData,
    headers,
  };
}

// Function to convert keys to readable format
export function convertToReadableFormat(str) {
  if (!str) {
    return { str };
  }
  const words = str.match(/[A-Z]?[a-z]+|\d+/g);

  if (!words) {
    return str;
  }

  const formattedWords = words.map((word) => {
    if (!isNaN(word)) {
      return word;
    } else {
      const lowercaseWord = word.toLowerCase();
      return lowercaseWord.charAt(0).toUpperCase() + lowercaseWord.slice(1);
    }
  });

  return formattedWords.join(' ');
}

export async function blobToString(blob) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
    reader.readAsText(blob);
  });
}

export function toCamelCaseInArray(arr) {
  return (
    arr?.map((s) => {
      return s
        .split('_')
        .map((word, index) => {
          if (index === 0) return word.toLowerCase();
          return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
        })
        .join('');
    }) || []
  );
}

export function toCamelCaseString(str) {
  return str
    .split(/-|_| /g)
    .map((word, index) => {
      if (index === 0) {
        return word.toLowerCase();
      }
      return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
    })
    .join('');
}

// deep object comparison
export function deepEqual(obj1, obj2) {
  if (obj1 === obj2) {
    return true;
  }

  if (
    obj1 == null ||
    typeof obj1 != 'object' ||
    obj2 == null ||
    typeof obj2 != 'object'
  ) {
    return false;
  }

  let keys1 = Object.keys(obj1);
  let keys2 = Object.keys(obj2);

  if (keys1.length != keys2.length) {
    return false;
  }

  for (let key of keys1) {
    if (!keys2.includes(key) || !deepEqual(obj1[key], obj2[key])) {
      return false;
    }
  }

  return true;
}

export const counterLines = {
  countStringLines(text) {
    if (!text) return 0;
    const lines = text.split('\n');
    return lines.length;
  },
  countCsvLines(csvText, includeHeader = true) {
    if (!csvText) return 0;

    const lines = csvText.trim().split('\n');

    return includeHeader ? lines.length : Math.max(0, lines.length - 1);
  },
};


export function getFilenameFromContentDispositionHeader(contentDisposition) {
  const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
  let matches = filenameRegex.exec(contentDisposition);
  if (matches != null && matches[1]) {
    return matches[1].replace(/['"]/g, '');
  }
  return null;
}