import React, { memo } from 'react';
import IconButton from '@mui/material/IconButton';
import { ic_close } from 'react-icons-kit/md/ic_close';
import { closeSnackbar } from 'notistack';
import Icon from '../Icon';

const SnackbarCloseButton = ({ snackbarKey }) => {
  return (
    <IconButton onClick={() => closeSnackbar(snackbarKey)}>
      <Icon icon={ic_close} size={24} style={{ color: '#fff' }} />
    </IconButton>
  );
};

export default memo(SnackbarCloseButton);
