'use strict';

import './AuthPageContainer.scss';

import classNames from 'classnames';
import React from 'react';
import { AlertModalContainer } from 'common/components/AlertModal';
import FlashedMessages from 'common/components/FlashedMessages';

const AuthPageContainer = (props) => {
  var contentClassName = classNames('PageContent', props.contentClassName);

  return (
    <div className="AuthPageContainer">
      <div className={contentClassName}>
        <div className="SiteLogo"></div>
        {props.children}
      </div>
      <AlertModalContainer />
      <FlashedMessages />
    </div>
  );
};

export default AuthPageContainer;
