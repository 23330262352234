'use strict';

import './AlertModal.scss';

import autobind from 'react-autobind';
import events from 'events';
import React from 'react';
import { Modal } from 'common/components/Modal';
import Button from 'common/components/Button';

// Global event router we'll use to allow the outside world to communicate with
// the spinner container.
var EVENT_EMITTER = new events.EventEmitter();

/**
 * Implements a 'Success' modal.
 */
class AlertModalContainer extends React.Component {
  constructor(props) {
    super(props);
    autobind(this);
    this.state = this.initialState();
  }

  initialState() {
    return {
      showModal: false,
      title: '',
      message: '',
      props: {},
      onExited: null,
    };
  }

  componentDidMount() {
    EVENT_EMITTER.on('show', this.handleShow);
  }

  componentWillUnmount() {
    EVENT_EMITTER.removeListener('show', this.handleShow);
  }

  render() {
    return (
      <Modal
        {...this.state.props}
        open={this.state.showModal}
        onClose={this.handleExited}
        className="AlertModal"
        maxWidth="sm"
        fullWidth={false}
        title={this.state.title}
        actions={
          <Button
            className="okay-button"
            type="submit"
            size="large"
            variant="contained"
            onClick={this.handleExited}
          >
            Okay
          </Button>
        }
      >
        <p>{this.state.message}</p>
      </Modal>
    );
  }

  handleShow(args) {
    // Need to special-case the onExited callback as this is when we should
    // reset the state back to the initial state.
    var { onExited, onExit, ...filteredProps } = args.props;

    this.setState({
      showModal: true,
      title: args.title,
      message: args.message,
      props: filteredProps,
      onExited: onExited || onExit,
    });
  }

  close(e) {
    this.setState({
      showModal: false,
    });
  }

  /**
   * Once the modal is fully exited, we should call any `onExited` callback that
   * the parent component passed us, and then reset the internal state back to
   * the initial state.
   */
  handleExited(e) {
    // If an `onExited` callback was passed in by the user, then call it
    // now. Then we can reset the state back to the initial state.
    if (this.state.onExited) {
      this.state.onExited(e);
    }

    this.setState(this.initialState());
  }
}

function show(title, message, props) {
  props = props || {};
  EVENT_EMITTER.emit('show', { title, message, props });
}

export { AlertModalContainer, show };
