import React from 'react';
import LoadingButton from '@mui/lab/LoadingButton';

const Button = (props) => {
  const { className, disabled, isLoading, loadingText, onClick } = props;

  let content = props.children;
  if (isLoading && loadingText) {
    content = [
      <div key="baseContent" style={{ height: 0, visibility: 'hidden' }}>
        {props.children}
      </div>,
      <div key="loadingText">{loadingText}</div>,
    ];
  }

  return (
    <LoadingButton
      variant="contained"
      {...props}
      className={`Button new_button_middle ${className}`}
      disabled={!!isLoading || !!disabled}
      onClick={!!isLoading || !!disabled ? null : onClick}
    >
      {content}
    </LoadingButton>
  );
};

export default Button;
