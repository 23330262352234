import React from 'react';

const SuccessIcon = () => {
  return (
    <svg
      width="35"
      height="37"
      viewBox="0 0 35 37"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Group 2085662307">
        <g id="Group 2085662308">
          <rect
            id="Rectangle 2843"
            x="0.5"
            y="0.5"
            width="26.1087"
            height="36"
            rx="6.5"
            fill="white"
            stroke="#0B4D16"
          />
          <g id="Group 2085662306">
            <circle
              id="Ellipse 110"
              cx="25.5053"
              cy="22.4623"
              r="7.71426"
              fill="#C7EDD5"
            />
            <path
              id="Vector"
              d="M28.1434 13.7321C26.4568 13.1703 24.6415 13.1213 22.927 13.5912C21.2125 14.0611 19.6758 15.0288 18.5113 16.3721C17.3468 17.7153 16.6068 19.3737 16.3848 21.1375C16.1628 22.9013 16.4689 24.6914 17.2642 26.2812C18.0596 27.8711 19.3085 29.1895 20.8531 30.0695C22.3977 30.9496 24.1686 31.3519 25.9419 31.2255C27.7151 31.0991 29.411 30.4497 30.8152 29.3595C32.2194 28.2692 33.2687 26.7871 33.8305 25.1005C34.5839 22.8388 34.408 20.3704 33.3414 18.2385C32.2749 16.1065 30.4051 14.4855 28.1434 13.7321ZM30.6385 20.4624L23.5632 26.3352C23.4423 26.4362 23.2987 26.5062 23.1446 26.5391C22.9906 26.572 22.8308 26.5668 22.6793 26.524L22.6252 26.506C22.4851 26.4584 22.3578 26.3794 22.2529 26.275C22.1481 26.1706 22.0687 26.0435 22.0206 25.9035L20.7 21.9898C20.6176 21.7459 20.6354 21.4791 20.7496 21.2483C20.8638 21.0174 21.065 20.8414 21.309 20.759C21.553 20.6765 21.8197 20.6943 22.0505 20.8085C22.2814 20.9227 22.4574 21.1239 22.5399 21.3679L23.4111 23.9382L29.4056 18.9685C29.6055 18.8252 29.8524 18.7632 30.0964 18.7949C30.3403 18.8267 30.5631 18.9499 30.7197 19.1396C30.8763 19.3294 30.955 19.5715 30.9399 19.817C30.9249 20.0625 30.8171 20.2932 30.6385 20.4624Z"
              fill="white"
              stroke="#0B4D16"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};

export default SuccessIcon;
