import { createTheme, adaptV4Theme } from '@mui/material/styles';

export const themeMui = createTheme(
  adaptV4Theme({
    typography: {
      fontFamily: 'Outfit',
      h1: {
        fontWeight: 300,
      },
      h2: {
        fontWeight: 300,
      },
      h3: {
        fontWeight: 300,
      },
      h4: {
        fontWeight: 300,
      },
      h5: {
        fontWeight: 300,
        fontSize: '24px',
      },
      h6: {
        fontWeight: 300,
      },
      subtitle1: {
        fontWeight: 300,
      },
      body1: {
        fontSize: '14px',
      },
      button: {
        textTransform: 'none',
        fontWeight: 400,
      },
    },

    palette: {
      mode: 'light',
      background: {
        default: '#fafafa',
      },
      primary: {
        main: '#1175DB',
      },
      text: {
        primary: '#333',
      },
    },
  })
);
