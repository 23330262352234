import React, { memo } from 'react';
import { MaterialDesignContent, SnackbarProvider } from 'notistack';
import SnackbarCloseButton from './SnackbarCloseButton';
import SuccessIcon from '../icon-components/SuccessIcon';
import { styled } from '@mui/material/styles';
import ErrorIcon from '../icon-components/ErrorIcon';
import WarningIcon from '../icon-components/WarningIcon';

const StyledMaterialDesignContent = styled(MaterialDesignContent)(() => ({
  '&.notistack-MuiContent-success': {
    backgroundColor: '#C7EDD5',
    color: '#0B2C4D',
    leadingTrim: 'both',

    textEdge: 'cap',
    fontFamily: 'Ranade',
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: 400,
    '#notistack-snackbar': {
      svg: {
        minWidth: '28px',
        marginRight: '12.5px',
      },
    },
    '.MuiIconButton-root': {
      position: 'absolute',
      top: 0,
      right: 0,
      svg: {
        fill: '#0B4D16 !important',
      },
    },
  },
  '&.notistack-MuiContent-error': {
    backgroundColor: '#FFBEBE',
    color: '#0B2C4D',
    leadingTrim: 'both',

    textEdge: 'cap',
    fontFamily: 'Ranade',
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: 400,
    '#notistack-snackbar': {
      svg: {
        minWidth: '28px',
        marginRight: '15px',
      },
    },
    '.MuiIconButton-root': {
      position: 'absolute',
      top: 0,
      right: 0,
      svg: {
        fill: '#B92626',
      },
    },
  },
  '&.notistack-MuiContent-warning': {
    backgroundColor: '#fff0af',
    color: '#0B2C4D',
    leadingTrim: 'both',

    textEdge: 'cap',
    fontFamily: 'Ranade',
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: 400,
    '#notistack-snackbar': {
      svg: {
        minWidth: '28px',
        marginRight: '15px',
      },
    },
    '.MuiIconButton-root': {
      position: 'absolute',
      top: 0,
      right: 0,
      svg: {
        fill: '#7a5d00',
      },
    },
  },
}));

const CustomSnackBarProvider = ({ children }) => {
  return (
    <SnackbarProvider
      maxSnack={4}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      Components={{
        success: StyledMaterialDesignContent,
        error: StyledMaterialDesignContent,
        warning: StyledMaterialDesignContent,
      }}
      iconVariant={{
        success: <SuccessIcon />,
        error: <ErrorIcon />,
        warning: <WarningIcon />,
        info: 'ℹ️',
      }}
      style={{
        boxShadow: 'none',
        maxWidth: '350px',
        flexWrap: 'nowrap',
        borderRadius: '16px',
        padding: '15px 21px',
      }}
      action={(snackbarKey) => <SnackbarCloseButton snackbarKey={snackbarKey} />}
    >
      {children}
    </SnackbarProvider>
  );
};

export default memo(CustomSnackBarProvider);
