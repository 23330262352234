'use strict';

import React from 'react';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import { enqueueSnackbar } from 'notistack';

import * as ajax from 'common/helpers/ajax';
import * as AlertModal from 'common/components/AlertModal';
import Button from 'common/components/Button';
import ReduxFormFloatingLabelFormGroup from 'common/components/ReduxFormFloatingLabelFormGroup';
import AuthPageContainer from 'auth/containers/AuthPageContainer';

const ResetPasswordConfirm = (props) => {
  return (
    <AuthPageContainer contentClassName="ResetPasswordConfirm">
      <ResetPasswordConfirmForm
        initialValues={{ resetPasswordToken: props.match.params.resetPasswordToken }}
        partner={props.partner}
        onSubmit={(data) => props.dispatch(resetPasswordConfirm(data))}
      />
    </AuthPageContainer>
  );
};

let ResetPasswordConfirmForm = (props) => {
  return (
    <form
      className="ResetPasswordConfirmForm defaultColumnFormStyle"
      onSubmit={props.handleSubmit}
    >
      <ReduxFormFloatingLabelFormGroup
        type="password"
        name="password"
        label="New password"
      />

      <Button
        type="submit"
        size="large"
        variant="contained"
        isLoading={props.submitting}
        disabled={props.pristine || props.submitting}
      >
        Set password
      </Button>

      <div className="footer-links">
        <ul className="list-inline">
          <li>
            <a href={'mailto:' + props.partner.supportEmail}>Contact</a>
          </li>
        </ul>
      </div>
    </form>
  );
};

const resetPasswordConfirm = (data) => {
  return (dispatch) => {
    return ajax.postJSON(
      '/api/auth/reset-password-confirm',
      data,
      (response) => {
        AlertModal.show(
          'Success!',
          "Your password has been reset, and now you're signed in!",
          {
            onExit: function (e) {
              window.location.replace(response.redirect);
            },
          }
        );
      },
      (response) => {
        enqueueSnackbar(response.message, { variant: 'error' });
      }
    );
  };
};

ResetPasswordConfirmForm = reduxForm({
  form: 'Auth.ResetPasswordConfirmForm',
})(ResetPasswordConfirmForm);

const mapStateToProps = (state) => {
  return {
    partner: state.partner,
  };
};

export default connect(mapStateToProps)(ResetPasswordConfirm);
