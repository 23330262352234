import React from 'react';
import { Switch, Route } from 'react-router-dom';

import CreateAccount from './components/CreateAccount';
import SignIn from './components/SignIn';
import ResetPasswordRequest from './components/ResetPasswordRequest';
import ResetPasswordConfirm from './components/ResetPasswordConfirm';

const routes = [
  {
    path: '/sign-in',
    exact: true,
    component: SignIn,
  },
  {
    path: '/reset-password',
    exact: true,
    component: ResetPasswordRequest,
  },
  {
    path: '/r/:resetPasswordToken',
    exact: true,
    component: ResetPasswordConfirm,
  },
  {
    path: '/create-account/',
    exact: false,
    component: CreateAccount,
  },
];

export default (
  <Switch>
    {routes.map((r) => (
      <Route key={r.path} path={r.path} exact={r.exact} component={r.component} />
    ))}
  </Switch>
);
