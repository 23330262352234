import { combineReducers } from 'redux';
import { _flashedMessages } from 'common/reducers.js';
import { reducer as formReducer } from 'redux-form';

function user(state = {}, action) {
  return state;
}

function shipper(state = {}, action) {
  return state;
}

function partner(state = {}, action) {
  return state;
}

export default combineReducers({
  _flashedMessages,
  form: formReducer,
  user,
  shipper,
  partner,
});
