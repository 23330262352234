import React from 'react';

const WarningIcon = (props) => (
  <svg
    width="35"
    height="37"
    viewBox="0 0 35 37"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="0.5" y="0.5" width="34" height="36" rx="6.5" fill="white" stroke="#dbb200" />
    <path d="M17.5,6 L29,31 H6 Z" fill="#dbb200" />
    <rect x="16.5" y="16" width="2" height="7" fill="white" />
    <circle cx="17.5" cy="27" r="1.5" fill="white" />
  </svg>
);

export default WarningIcon;
