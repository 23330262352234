'use strict';

import React from 'react';
import { connect } from 'react-redux';
import { enqueueSnackbar } from 'notistack';

/**
 * Show flashed messages when this component gets mounted, and then immediately
 * clear them.
 */
class FlashedMessages extends React.Component {
  componentDidMount() {
    this.props._flashedMessages.forEach((tup) => {
      var [type, message] = tup;
      enqueueSnackbar(message, { variant: type });
    });
    this.props.dispatch({ type: 'FLASHED_MESSAGES__CLEAR' });
  }

  render() {
    return null;
  }
}

const mapStateToProps = (state) => {
  return {
    _flashedMessages: state._flashedMessages,
  };
};

export default connect(mapStateToProps)(FlashedMessages);
