export function _flashedMessages(state = [], action) {
  switch (action.type) {
    case 'FLASHED_MESSAGES__CLEAR':
      return [];
    case 'FLASHED_MESSAGES__ADD':
      return state.concat(action.payload);
    case 'FLASHED_MESSAGES__SET':
      return action.payload;
    default:
      return state;
  }
}
